import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let LawnmastersLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 160 25"}>
		<title>Lawn Masters</title>

		<g>
			<path
				fill="#59BD76"
				d="M15.76,14.27c0.03,0.01,0.05,0.04,0.04,0.07l-1.44,5.03c-0.01,0.04-0.01,0.08,0.01,0.12
		c0.45,1.02,0.88,2.1,1.27,3.22c0.01,0.04,0.03,0.04,0.05,0.01c0.86-1.15,1.4-2.41,1.62-3.77c0.41-2.54-0.38-5.11-1.71-7.3
		c-1.32-2.18-3.13-4.06-5.14-5.64C8,4.08,5.21,2.47,2.11,1.2C2.06,1.18,2.05,1.19,2.07,1.24C2.92,2.91,3.54,4.74,4,6.61
		C4.27,7.7,4.49,8.68,4.64,9.55c0.3,1.7,0.59,3.42,0.89,5.17c0.11,0.68,0.29,1.43,0.52,2.23c0.35,1.23,0.84,2.41,1.64,3.4
		c0.72,0.9,1.69,1.63,2.7,2.14c1.13,0.57,2.33,1.01,3.61,1.32c0.01,0,0.03-0.01,0.03-0.02c0,0,0-0.01,0-0.01
		c-0.16-0.8-0.37-1.59-0.61-2.37c-0.01-0.02-0.02-0.04-0.04-0.05l-5.77-2.85c-0.02-0.01-0.03-0.04-0.02-0.06l0.09-0.22
		c0.01-0.03,0.04-0.04,0.07-0.03l5.13,1.7c0.03,0.01,0.04,0,0.03-0.03c-0.46-1.25-0.97-2.47-1.53-3.67
		c-0.02-0.04-0.05-0.07-0.08-0.08l-3.64-1.77c-0.04-0.02-0.05-0.05-0.04-0.09l0.06-0.2C7.66,14.02,7.7,14,7.74,14.01l2.93,0.86
		c0.06,0.02,0.07,0,0.04-0.05c-1.4-2.79-2.89-5.55-4.47-8.27C6.23,6.52,6.23,6.5,6.26,6.48l0.2-0.14c0.02-0.02,0.06-0.01,0.07,0.01
		c1.44,2.01,2.81,4.05,4.11,6.14c0.01,0.01,0.02,0.01,0.03,0.01c0,0,0.01-0.01,0.01-0.01l0.87-2.46c0.01-0.02,0.03-0.03,0.05-0.03
		l0.23,0.06c0.04,0.01,0.06,0.04,0.05,0.08l-0.51,3.47c-0.01,0.05,0,0.1,0.03,0.14c0.84,1.42,1.62,2.87,2.33,4.35
		c0,0.01,0.02,0.01,0.03,0.01c0,0,0.01-0.01,0.01-0.01l1.71-3.88c0.01-0.03,0.05-0.04,0.07-0.03L15.76,14.27z"
			/>
			<g>
				<path fill="#222222" d="M23.68,7.12h2.91v12.16h6.91v2.81h-9.82V7.12z" />
				<path
					fill="#222222"
					d="M39.03,22.31c-1.22,0-2.16-0.3-2.84-0.91c-0.68-0.61-1.01-1.4-1.01-2.39c0-1.16,0.44-2.07,1.33-2.73
			c0.89-0.66,2.1-0.98,3.65-0.98h2.91v-0.14c0-0.6-0.2-1.09-0.59-1.46s-1-0.55-1.8-0.55c-0.63,0-1.15,0.13-1.58,0.38
			c-0.42,0.25-0.77,0.61-1.02,1.08l-2.6-1.29c0.56-0.74,1.26-1.34,2.1-1.81c0.84-0.47,1.88-0.71,3.13-0.71c1.64,0,2.9,0.4,3.79,1.19
			s1.33,1.89,1.33,3.3v6.81h-2.73v-1.7c-0.4,0.57-0.95,1.04-1.67,1.39C40.7,22.13,39.91,22.31,39.03,22.31z M39.85,19.97
			c0.34,0,0.68-0.05,1-0.14s0.64-0.23,0.92-0.41c0.29-0.18,0.54-0.39,0.77-0.63c0.23-0.25,0.41-0.52,0.54-0.82v-0.59h-2.6
			c-0.85,0-1.45,0.12-1.81,0.37c-0.36,0.25-0.54,0.59-0.54,1.03C38.13,19.57,38.7,19.97,39.85,19.97z"
				/>
				<path
					fill="#222222"
					d="M46.68,11.01h2.87l2.23,7.22l2.62-7.22h2.52l2.58,7.26l2.32-7.26h2.87l-3.75,11.07h-2.66l-2.64-7.22
			l-2.66,7.22h-2.71L46.68,11.01z"
				/>
				<path
					fill="#222222"
					d="M66.13,11.01h2.83v1.39c0.36-0.51,0.81-0.9,1.36-1.19c0.55-0.29,1.18-0.43,1.88-0.43
			c1.31,0,2.35,0.41,3.13,1.22c0.77,0.81,1.16,1.94,1.16,3.39v6.68h-2.89v-6.25c0-0.79-0.19-1.42-0.56-1.88
			c-0.38-0.46-0.94-0.69-1.69-0.69c-0.67,0-1.23,0.24-1.68,0.71c-0.45,0.47-0.68,1.14-0.68,2v6.11h-2.85V11.01z"
				/>
				<path
					fill="#222222"
					d="M78.86,7.12h2.81l4.45,6.17l4.61-6.17h2.69v14.96h-2.83v-10.5l-4.49,6.03l-4.37-5.92v10.39h-2.87V7.12z"
				/>
				<path
					fill="#222222"
					d="M99.09,22.31c-1.22,0-2.16-0.3-2.84-0.91c-0.68-0.61-1.01-1.4-1.01-2.39c0-1.16,0.44-2.07,1.33-2.73
			c0.89-0.66,2.1-0.98,3.65-0.98h2.91v-0.14c0-0.6-0.2-1.09-0.59-1.46s-1-0.55-1.8-0.55c-0.63,0-1.15,0.13-1.58,0.38
			c-0.42,0.25-0.77,0.61-1.02,1.08l-2.6-1.29c0.56-0.74,1.26-1.34,2.1-1.81c0.84-0.47,1.88-0.71,3.13-0.71c1.64,0,2.9,0.4,3.79,1.19
			s1.33,1.89,1.33,3.3v6.81h-2.73v-1.7c-0.4,0.57-0.95,1.04-1.67,1.39C100.76,22.13,99.97,22.31,99.09,22.31z M99.91,19.97
			c0.34,0,0.68-0.05,1-0.14s0.64-0.23,0.92-0.41c0.29-0.18,0.54-0.39,0.77-0.63c0.23-0.25,0.41-0.52,0.54-0.82v-0.59h-2.6
			c-0.85,0-1.45,0.12-1.81,0.37c-0.36,0.25-0.54,0.59-0.54,1.03C98.19,19.57,98.76,19.97,99.91,19.97z"
				/>
				<path
					fill="#222222"
					d="M112.39,22.31c-1.32,0-2.41-0.24-3.25-0.71s-1.48-1.15-1.92-2.04l2.73-1.07c0.49,1,1.34,1.5,2.54,1.5
			c0.57,0,1.01-0.12,1.31-0.36c0.3-0.24,0.45-0.52,0.45-0.85c0-0.21-0.07-0.37-0.2-0.5c-0.14-0.13-0.33-0.24-0.56-0.33
			c-0.24-0.09-0.52-0.17-0.83-0.24s-0.65-0.14-1-0.21c-0.46-0.1-0.93-0.21-1.4-0.35c-0.47-0.14-0.9-0.33-1.27-0.57
			c-0.38-0.25-0.68-0.56-0.91-0.93s-0.35-0.86-0.35-1.45c0-0.46,0.11-0.91,0.33-1.33c0.22-0.42,0.53-0.79,0.92-1.11
			s0.87-0.56,1.42-0.75c0.55-0.18,1.17-0.28,1.86-0.28c1.18,0,2.13,0.21,2.87,0.62s1.37,0.99,1.89,1.73l-2.58,1.05
			c-0.23-0.37-0.52-0.66-0.87-0.86c-0.35-0.21-0.78-0.31-1.3-0.31c-0.56,0-0.97,0.1-1.24,0.3s-0.4,0.45-0.4,0.77
			c0,0.36,0.22,0.6,0.66,0.75s1.06,0.3,1.87,0.48c0.46,0.1,0.93,0.21,1.4,0.35c0.47,0.14,0.9,0.33,1.29,0.58
			c0.39,0.25,0.71,0.58,0.95,0.97c0.25,0.4,0.37,0.89,0.37,1.48c0,0.44-0.1,0.87-0.29,1.3c-0.19,0.43-0.48,0.82-0.88,1.17
			s-0.89,0.63-1.49,0.85C113.9,22.2,113.2,22.31,112.39,22.31z"
				/>
				<path
					fill="#222222"
					d="M117.97,11.01h2.05v-2.4l2.79-1.64v4.04h2.91v2.5h-2.91v4.55c0,0.59,0.11,1,0.34,1.23
			c0.23,0.23,0.52,0.35,0.87,0.35c0.37,0,0.71-0.14,1.02-0.41l1.29,2.21c-0.36,0.27-0.76,0.48-1.2,0.63
			c-0.44,0.14-0.93,0.21-1.45,0.21c-1.16,0-2.06-0.31-2.71-0.93s-0.96-1.68-0.96-3.19v-4.65h-2.05V11.01z"
				/>
				<path
					fill="#222222"
					d="M132.57,22.31c-0.89,0-1.69-0.15-2.42-0.44c-0.72-0.29-1.34-0.7-1.86-1.22c-0.51-0.52-0.91-1.13-1.18-1.82
			c-0.27-0.7-0.41-1.45-0.41-2.25c0-0.81,0.15-1.56,0.44-2.27c0.29-0.7,0.7-1.31,1.22-1.83c0.52-0.52,1.13-0.93,1.84-1.23
			c0.71-0.3,1.48-0.45,2.3-0.45c0.83,0,1.6,0.14,2.3,0.43c0.7,0.29,1.3,0.68,1.8,1.18c0.5,0.5,0.9,1.1,1.19,1.79
			c0.29,0.7,0.43,1.46,0.43,2.28v1.07h-8.79c0.19,0.72,0.56,1.29,1.12,1.71c0.55,0.42,1.24,0.62,2.06,0.62
			c0.66,0,1.21-0.13,1.66-0.4s0.81-0.62,1.09-1.08l2.32,1.35c-0.56,0.78-1.24,1.4-2.05,1.87C134.81,22.07,133.79,22.31,132.57,22.31
			z M132.5,13.18c-0.72,0-1.35,0.19-1.87,0.57c-0.52,0.38-0.9,0.9-1.13,1.56h5.9c-0.19-0.7-0.54-1.23-1.05-1.59
			C133.86,13.37,133.24,13.18,132.5,13.18z"
				/>
				<path
					fill="#222222"
					d="M140.07,11.01h2.79v1.7c0.27-0.66,0.67-1.14,1.2-1.44c0.53-0.31,1.14-0.46,1.86-0.46
			c0.37,0,0.71,0.04,1.04,0.12c0.32,0.08,0.6,0.18,0.83,0.31l-1.03,2.79c-0.15-0.11-0.36-0.2-0.61-0.28
			c-0.26-0.07-0.55-0.11-0.86-0.11c-0.72,0-1.3,0.2-1.74,0.6c-0.44,0.4-0.66,1.07-0.66,2v5.84h-2.81V11.01z"
				/>
				<path
					fill="#222222"
					d="M153.19,22.31c-1.33,0-2.41-0.24-3.25-0.71c-0.84-0.47-1.48-1.15-1.92-2.04l2.73-1.07
			c0.49,1,1.34,1.5,2.54,1.5c0.57,0,1.01-0.12,1.31-0.36c0.3-0.24,0.45-0.52,0.45-0.85c0-0.21-0.07-0.37-0.2-0.5
			c-0.14-0.13-0.33-0.24-0.56-0.33c-0.24-0.09-0.52-0.17-0.83-0.24s-0.65-0.14-1-0.21c-0.46-0.1-0.93-0.21-1.4-0.35
			c-0.47-0.14-0.9-0.33-1.27-0.57c-0.38-0.25-0.68-0.56-0.91-0.93s-0.35-0.86-0.35-1.45c0-0.46,0.11-0.91,0.33-1.33
			c0.22-0.42,0.53-0.79,0.92-1.11s0.87-0.56,1.42-0.75s1.17-0.28,1.85-0.28c1.18,0,2.13,0.21,2.87,0.62s1.37,0.99,1.89,1.73
			l-2.58,1.05c-0.23-0.37-0.52-0.66-0.87-0.86s-0.78-0.31-1.3-0.31c-0.56,0-0.97,0.1-1.24,0.3c-0.27,0.2-0.4,0.45-0.4,0.77
			c0,0.36,0.22,0.6,0.66,0.75s1.06,0.3,1.87,0.48c0.46,0.1,0.93,0.21,1.4,0.35s0.9,0.33,1.29,0.58c0.39,0.25,0.71,0.58,0.95,0.97
			c0.25,0.4,0.37,0.89,0.37,1.48c0,0.44-0.1,0.87-0.29,1.3c-0.19,0.43-0.49,0.82-0.88,1.17c-0.4,0.35-0.89,0.63-1.49,0.85
			S153.99,22.31,153.19,22.31z"
				/>
			</g>
		</g>
	</SvgIcon>
);

LawnmastersLogo = memo(LawnmastersLogo);
LawnmastersLogo.displayName = "LawnmastersLogo";
LawnmastersLogo.muiName = "LawnmastersLogo";

export default LawnmastersLogo;
